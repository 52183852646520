@import './../../styles/variables.scss';

$menu-padding : 0.7vw 0px;

.sidebar{
    background: #fff;
    position: fixed;
    left:0;
    top: 0;
    width: 50%;
    height: 100vh;
    z-index: 100;
    overflow-y: auto; 
    transition: .4s all ease-in-out;
    transform: translateX(-100%);

    @media only screen and (min-width: $md-breakpoint) {
        border-right: 1px solid #0F1225;
        top: 10px;
    }

    &.open-menu{
        transform: translateX(0);
    }

    &::-webkit-scrollbar{
        transition: .4s ease-in-out;
        width: 3px  !important; 
    }

    &::-webkit-scrollbar-track{
        transition: .4s ease-in-out;
        background: #fff;
    }
    
    &::-webkit-scrollbar-thumb{
        background: #0F1225;
        border-radius: 5px;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 38px;
        justify-content: space-between;
    }
    
    .close-btn{
        display: block;
        margin-right: 20px;
        width: max-content;
        cursor: pointer;
    }

    .title{
        padding-left: 20px; 
        margin:0;
        font-weight: 700;
        color: #0F1225;
    }

    .menu-item-container{
        flex:1;
        padding :0 20px;
        height : 60px;
        display: flex;
        align-items:center;
        justify-content : space-between;
        transition: .4s all ease;
        text-decoration: none;
        cursor: pointer;

        &:hover{
            background : #f2f2f2;
        }

        &.main-active{
            background : #f2f2f2;
        }

        .menu-item-group{
            display: flex;
            align-items: center;
            width: max-content;
        }

        .menu-item{
            // width: 145px;
            padding-left: 15px; 
            font-size: calc(10px + (16 - 12) * ((100vw - 320px) / (1600 - 320)));
            font-weight: 600;
            color:#0F1225;
            transition: .4s all ease;
            
            &.menu-item-active{
                color: #0F1225;
            }
        }

        .menu-icon{
            width: 16px;
            color: #0F1225;
        }

        .menu-toggle{
            transition: .4s ease-in-out;
        }

        .menu-toggle-open{
            transform: rotate( 90deg );            
            transform-origin: center initial;            
        }
    }

    .menu-subitem-container{
        height : 0;
        overflow: hidden;
        transition: .4s all ease;
        &.open{
            height: auto;
        }

        .menu-subitem{
            height : 40px;
            display: flex;
            align-items: center;
            // border-top : 1px solid #203A7B;
            text-decoration: none;
            cursor: pointer;
            transition: .4s all ease;

            &:hover{
                background : #f2f2f2;
            }
            &.sub-active{
                background : #f2f2f2;
            }
            &.last{
               border-bottom : 1px solid #203A7B;
            }
            .menu-subitem-text{
                font-size: calc(8px + (16 - 12) * ((100vw - 320px) / (1600 - 320)));
                padding-left: 55px;
                font-weight: 500;
                color: #0F1225;
            }
        }
        
    }

}

@media (min-width: $sm-breakpoint) {
    .sidebar{
        transform: translateX(0);
        width: 17vw;

        &::-webkit-scrollbar{
            transition: .4s ease-in-out;
            width: 10px; 
        }

        .header{
            margin-top: 0px;
        }

        .close-btn{
            display: none;
        }
    }
}