@import '../../styles/variables.scss';

.dashboard-wrapper{
    min-height: 100vh;
    position: relative;
    top: 80px;

    @media (min-width: $sm-breakpoint) {
        top: 65px;
    }

    .content {
        padding: 1em;

        @media (min-width: $sm-breakpoint) {
            color: #0F1225;
            overflow: auto;
            position: absolute;
            right: 0;
            width: 82vw;
            margin: 0 auto;
            box-sizing: border-box;
        }
    }
}