@import './../../styles/variables.scss';

.backdrop{
    @media only screen and (max-width: $md-breakpoint) {
        background-color: #4E5A72;
        opacity: .4;
        width: 100%;
        min-height: 100vh;
        position: absolute;
        left: 0;
        z-index: 10;
    }
}

.navigations {
    width: 100%;
    box-sizing: border-box;

    .navbar {
        display: flex;
        height: max-content;
        box-shadow: 0px 3px 2px #ddd;
        color: black;
        position: fixed;
        top: 0;
        flex-direction: column;
        width: 100%;
        align-items: center;
        z-index: 1000;
        background: #fff;
        box-sizing: border-box;
        padding: 0 1em;

        @media (min-width: $sm-breakpoint) {
            flex-direction: row;
            height: 50px;
        }
    
        .navs {
            position: absolute;
            left: 0;
            margin: .5em 1em;

            i {
                cursor: pointer;
                font-size: 24px;
                height: 24px;
            }

            .fa {
                @media (min-width: $sm-breakpoint) {
                    display: none;
                }
            }
        }

        .title{
            white-space: nowrap;
        }
    
        .icons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border-top: 1px solid rgb(216, 215, 215);
            margin-top: 4px;

            @media (min-width: $sm-breakpoint) {
                justify-content: flex-end;
                border-top: none;
                margin-top: 0;
            }
    
            i {
                cursor: pointer;
                font-size: 24px;
                height: 24px;
                width: 24px;
                text-align: right;
            }
    
            img {
                border-radius: 50%;
                cursor: pointer;
            }

            p{
                @media (min-width: $sm-breakpoint) {
                    margin: 0 1em
                }
            }
        }
    }
}