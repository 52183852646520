@import './styles/variables.scss';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $base-color;
  background-color: #fff;

  font-size: 14px;
  @media (min-width: $md-breakpoint) {
    font-size: 16px;
  }
};

.loader-container{
  height : 100vh;
  display: flex;
  align-items: center;
  justify-content : center;
}

.body-section{
  // border: 1px solid #f2f2f2;
  border-radius: 5px;
  display: grid;
  grid-row: auto;
  grid-template-columns: repeat( auto-fill, minmax(300px, 1fr) );
  // grid-auto-columns: repeat( auto-fill, minmax(300px, 1fr) );
  justify-content: space-between;
}

.card-wrapper{
  display: flex;
  margin: 20px 0;
  flex: 1;
  display: flex;
  justify-content: center;
  height: auto;

  // @media (min-width: $md-breakpoint) {
  //   margin-right: 15px;
  // }
}

