$transitionEffect : .4s all ease-in-out;

.notification-container{

    position : fixed;
    background : none;
    z-index: 400000;
    transition: $transitionEffect;
    top:0;
    right:0;
    width: max-content;
    height: max-content;
    cursor: pointer;

    &.open {
        transform: translateX(0%);
    }
    
    &.close {
        transform: translateX(100%);
    }

    .notification-bg { 
        width: 400px;
        height : max-content;
        background : #eeeeee;
        // box-shadow: 1px 1px 2px 0.5px #262626;
        transition: $transitionEffect;  
        margin-right: -30px;
        margin-top: 20px;
        border-radius: 5px;
        padding : 20px 0;

        .notification-header{
            height : max-content;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-top: 10px;
            padding-left: 10px;
            padding-bottom: 10px;

            p{
                margin:0;
                font-size: 17px;
                font-weight: 600;
                line-height: 20px;
                color : #000;

                &.true{
                    color : green;
                }

                &.false{
                    color : red;
                }
            }
        }

        .notification-body{
            padding : 0px 15px 20px;

            .notification-content{
                display: flex;
                align-items: flex-start;  

                p{
                    margin:0;
                    font-size: 13px;
                    font-weight: 600;
                    color : #000;
                }
            }            
        }
    }

}